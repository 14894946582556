import React from 'react'
import LogoSVG from '../../images/z4m-logo-v3.inline.svg'
import { css } from '@emotion/core'


const container = css`
    height: 100%;
    width: 100%;

`


export default () => {
    return (
        <div css={container}>
                <LogoSVG />
        </div>
    )
}