import React from 'react'
import 'normalize.css'
import { css } from '@emotion/core'

import Layout from '../components/Layout'
import LandingHeader from '../components/LandingHeader/LandingHeader'
import ContentBlock from '../components/LandingContentBlock/ContentBlock'
import Card from '../components/Card/Card'
import Contact from '../components/Contact/Contact'
import SEO from '../components/SEO/SEO'

import './scss/index.scss';


const placeholder = css`
    width: 100%;
    height: 300px;
    border: solid 1px black
`
const icon_placeholder = <img src="https://placeholder.pics/svg/100x100" />


export default () => ( 
    <Layout>
        <SEO />
        <LandingHeader />
        <ContentBlock class='bg-img' id='intro'>
            <div className={'intro'}>
                <div>
                At Zest 4 Maths we understand that a ‘one size fits all’ approach does not work for everyone. We individually tailor a maths programme that is suited to your needs. Your specific programme is designed to continually evolve to cater for you as you thrive and grow.
                </div>
            </div>
            <div className={'card_container'}>
            <Card 
                title="Face-To-Face"
                preTitle="01."
                text="Personal and tailored mathematics tuition in a relaxed and comfortable environment."
                buttonLabel="Learn More"
            />
            <Card 
                title="Online Tuition" 
                preTitle="02."
                text="Log in and connect from the comfort of your own home. Receiving tailored mathematics tuition has never been easier."
                buttonLabel="Learn More"
            />
            <Card 
                title="Online Courses"
                preTitle="03."
                text="Complete a variety of professional video courses in your own time to solidify your learning before an exam or test."
                buttonLabel="Learn More"
            />
            </div>
            <div className={'outro'}>
                <div>
                As a student of Zest 4 Maths, you will become motivated, encouraged and confident in your maths ability. This will help you stay focused, giving you the incentive to reach your full potential.
                </div>
            </div>
        </ContentBlock>
        <ContentBlock>
            <Contact></Contact>
        </ContentBlock>
    </Layout>
)
