import React from 'react'
import './Contact.scss'

import LinkedInSVG from '../../images/linkedin.inline.svg';
import FacebookSVG from '../../images/facebook.inline.svg';
import ContactForm from './ContactForm/ContactForm';

export default () => {
    return (
        <>
        <script src="https://smtpjs.com/v3/smtp.js"></script>
        {/* <h1 className="header-text">We'd love to hear from you</h1> */}
        <div className="contact-container">
            
            <div className="header-column">
                
                <p className="subheader-text">You can find us on Facebook and Karen on LinkedIn</p>
                <div className="social-container">
                    <div className="social-icon">
                        <a href='https://www.facebook.com/Zest4Maths.co.nz' target='_blank'>
                            <FacebookSVG />
                        </a>
                    </div>
                    <div className="social-icon">
                        <a href='https://www.linkedin.com/in/karen-benn/' target='_blank'>
                            <LinkedInSVG />
                        </a>
                        
                    </div>

                </div>
            </div>
            <div className="contact-form-container">
                <div className="contact-form-card">
                    <h2 className='header-text'>We'd love to hear from you 👋</h2>
                    <ContactForm />
                </div>  
            </div>  
        </div>
        </>
    )
}