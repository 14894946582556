import React, { useState } from "react";
import { Formik, Field, Form, useField } from "formik";

import "./ContactForm.scss";

const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className="form-item">
      <label hidden htmlFor={props.id || props.name}>
        {label}
      </label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className="textbox form-item">
      <label htmlFor={props.id || props.name}>{label}</label>
      <Field
        name="message"
        as="textarea"
        className="form-input"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="select form-item">
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  } else if (values.lastName.length > 20) {
    errors.lastName = "Must be 20 characters or less";
  }
  if (!values.contactNumber) {
    errors.contactNumber = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  }
  if (!values.message) {
    errors.message = "Required";
  }
  return errors;
};

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [sent, setSent] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    console.log(values);
    setSubmitting(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => {
        setSubmitting(false);
        setSent(true);
        resetForm();
      })
      .catch((error) => alert(error));
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        contactNumber: "",
        whoAmI: "parent",
        message: "",
      }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      <Form data-netlify="true" name="contact" method="post">
        <div className="form-row">
          <MyTextInput
            label="First Name"
            name="firstName"
            type="text"
            placeholder="First Name"
          />
          <MyTextInput
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Last Name"
          />
        </div>
        <div className="form-row">
          <MyTextInput
            label="Contact Number"
            name="contactNumber"
            type="text"
            inputmode="numeric"
            placeholder="Contact Number"
          />
          <MyTextInput
            label="Email"
            name="email"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="form-row">
          <MyTextInput
            label="School Name"
            name="schoolName"
            type="text"
            placeholder="School"
          />
          <MySelect label="I am a" name="whoAmI">
            <option value="parent">Parent</option>
            <option value="student">Student</option>=
          </MySelect>
        </div>
        <div className="form-row">
          <MySelect label="Level" name="yearLevel">
            <option value="year1">Year 1</option>
            <option value="year2">Year 2</option>
            <option value="year3">Year 3</option>
            <option value="year4">Year 4</option>
            <option value="year5">Year 5</option>
            <option value="year6">Year 6</option>
            <option value="year7">Year 7</option>
            <option value="year8">Year 8</option>
            <option value="year9">Year 9</option>
            <option value="year10">Year 10</option>
            <option value="year11">Year 11</option>
            <option value="year12">Year 12</option>
            <option value="year13">Year 13</option>
            <option value="adult">Adult</option>
          </MySelect>
        </div>
        <div className="form-row">
          <MyTextArea
            // label="Message"
            name="message"
            type="textarea"
            placeholder="Message"
            rows="4"
          />
        </div>
        <div className="form-row submit-row">
          <div className={`success-message ${!sent ? "hidden" : ""}`}>
            Message sent! <span className="success-message__emoji">👍</span>
          </div>
          <button
            type="submit"
            className={`form-item button ${sent ? "sent" : ""}`}
          >
            {submitting ? <>Sending</> : <>Send</>}
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default ContactForm;
