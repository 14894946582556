import React from 'react'
import Logo from '../Logo/Logo'
import Button from '../Button/Button'
import './LandingHeader.scss'


export default () => {

    const learnMoreClick = () => {
        document.getElementById('intro').scrollIntoView({ behavior: 'smooth', block: "start" });
    }
    const contactClick = () => {
        const target = document.getElementsByName('firstName')[0]
        target.focus()
    }

    

    return (
    <div className="landing-header-container">
        <div className="title-container">
                <h1 className="header-text">Zest 4 Maths</h1>
                <p className="subheader-text">We provide students with tailored maths tuition that really works. Lifting them to a place of confidence, supporting them to reach their full potential.</p>
            <div className="cta-button-container">
                <Button handleClick={learnMoreClick} label='Learn More' type='--right-margin'/>
                <Button handleClick={contactClick} label='Contact'/>
            </div>
        </div>
        <div className="logo-container">
            <Logo/>
        </div>
    </div>
    )
}

