import React from 'react'
import './Card.scss'


export default (props) => {

    

    return(
        <div className="card-container">
            <h2 className="card-title"><span className="cart-title--pre">{props.preTitle}</span><br></br> {props.title}</h2>
            <p className="card-text">{props.text}</p>
        </div>
    )
}